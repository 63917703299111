.contactMe-logo {
  margin-top: 30px;
  width: 300px;
}

.contactMe-logo_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactMe__Title {
  color: #f7f7f7;
  font-size: 80px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  /* text-transform: uppercase; */
  margin-top: 50px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: max-content;
  padding: 25px;
}

.contactMe-submit_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactBackground {
  margin-top: -10rem;
  object-fit: cover;
  width: 100%;
  height: 110%;
  position: fixed;
  z-index: -1;
}

input[type="text"],
input[type="email"],
input[type="number"],
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

label[for="name"],
label[for="email"],
label[for="number"],
label[for="message"] {
  color: rgb(122, 146, 122);
}

text[for="name"],
text[for="email"],
text[for="number"] {
  font-size: 20px;
}

textarea {
  height: 150px;
}

.container1 {
  border-radius: 5px;
  background-color: #1c37238c;
  padding: 20px;
  border: #1c3723;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  max-width: fit-content;
  min-width: 100%;

  height: 535px;
  width: 800px;
}

.container2 {
  border-radius: 5px;
  background-color: #1c3723;
  padding: 20px;
  border: #1c3723;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  max-width: fit-content;
  min-width: 100%;
  height: 535px;
  width: 800px;
}

.contactMe_row {
  display: flex;
  /* padding-left: 250px; */
}

.column1 {
  width: 50%;
  height: 100px;
  padding-left: 50px;
}

.column2 {
  width: 95%;
  max-width: fit-content;
  min-width: fit-content;
}

@media screen and (max-width: 840px) {
  .column1 {
    padding-left: 0;
    margin-left: auto;
  }
  .column2 {
    margin-right: auto;
  }

  .contactMe_row {
    padding-left: 0;
  }
}

@media screen and (max-width: 640px) {
  .container1 {
    margin-left: auto;
    margin-right: auto;
  }

  .container2 {
    display: none !important;
    font-size: 1px;
    height: 500px;
  }

  .column1 {
    padding-left: 20px;
    max-width: fit-content;
    width: 90%;
    height: 100%;
  }

  textarea {
    height: 110px;
  }
}

@media screen and (max-width: 320px) {
  .contactMe__Title {
    font-size: 4rem;
  }
}
