.experiencePageHero__section {
  padding: 25px 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fafafa;
}

.experiencePageHero__container-buttons {
  width: 119%;
}

.experiencePageHero__container-card {
  background: linear-gradient(45deg, #1c3723 0%, #1c3723 100%);
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
  width: 280px;
  height: 560px;
  text-decoration: 4px;
  border-radius: 4px;
  text-align: center;
}

.experiencePageHero__container-card:nth-child(2) {
  transform: scale(1.05);
  background: #25ce4a;
  margin: 0 24px;
  text-align: center;
}

.experiencePageHero__container-card:hover {
  transform: scale(1.06);
  transition: all 0.3s ease-out;
  color: #1c2237;
}

.experiencePageHero__container {
  display: flex;
  justify-content: center;
  align-content: center;
}

.experiencePageHero__wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0 auto;
}

.experiencePageHero__heading {
  color: #f00946;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
}

.experiencePageHero__container-cardInfo {
  display: flex;
  flex-direction: column;
  height: 500px;
  padding: 24px;
  align-items: center;
  color: #fafafa;
  width: 90%;
  text-align: center;
}

.experiencePageHero__container-cardInfo h3 {
  margin-bottom: 5px;
  font-size: 24px;
  text-align: center;
}

.experiencePageHero__container-cardInfo h4 {
  font-size: 30px;
  margin-bottom: 24px;
  text-align: center;
}
.experiencePageHero__container-cardInfo p {
  display: flex;
  padding-bottom: 10px;
  font-weight: bold;
}

.experiencePageHero__container-cardInfo p:nth-child(2) {
  display: flex;
  padding-bottom: 10px;
  font-weight: bold;
}

.experiencePageHero__container-features {
  margin: 16px 0px 32px;
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-bottom: -0.3rem;
  /* align-items: center; */
}

.experiencePageHero__container-features li {
  margin-bottom: 10px;
}

.icon {
  margin: 24px 0;
}

.experience-hero_title {
  margin-left: 25px;
  text-align: center;
}

/* Media Queries (Handles the components when the screen is shrinked) */
@media screen and (max-width: 960px) {
  .experiencePageHero__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .experiencePageHero__container-card {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
  }

  .experiencePageHero__wrapper {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .experiencePageHero__container-card:nth-child(2) {
    transform: scale(1);
    background: #242424;
    margin: 24px;
  }
  .experiencePageHero__container-card:hover {
    transform: none;
  }

  .experiencePageHero__container-features {
    margin: 0px;
  }

  .experiencePageHero__container-cardInfo {
    width: 99%;
  }

  .card__header:nth-child(2) {
    margin-top: -1rem;
    /* padding: 0rem; */
  }

  .card__header {
    margin-top: -1rem;
    /* padding: 0rem; */
  }

  .icon {
    margin-top: 0rem;
  }
  /* .experiencePageHero__heading {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    } */
}
