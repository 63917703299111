.aboutMe__hero-section {
  background: #fafafa;
  /* padding: 160px 0; */
  margin-top: -1rem;
}

.aboutMe__hero-row {
  align-items: center;
}

.row {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
  align-content: stretch;
}

.col {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
}

.aboutMe__hero-text-wrapper {
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
}

.aboutMe__hero-top-line {
  color: #f00946;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.aboutMe__heading {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #f7f8fa;
}

.aboutMe__heading-dark {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #1c3723;
}

.dark {
  color: #1c3723;
}

.darkBg {
  background-color: #1c3723;
}

.aboutMe__hero-subtitle {
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: rgb(122, 146, 122);
}

.aboutMe__hero-subtitle-dark {
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: #1c2237;
}

.aboutMe__hero-img-wrapper {
  max-width: 555px;
}

.aboutMe__hero-img {
  max-width: 95%;
  /* The new margin on line 78 does the same thing!
      margin-top: 0;
      margin-right: 0;
      margin-left: 10px; */
  padding-right: 0;
  margin: 0 0 0 10px;
  max-width: max-content;
  width: 80%;
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

@media screen and (max-width: 415px) {
  .aboutMe__hero-section {
  }
  .aboutMe__hero-text-wrapper {
    white-space: normal;
    padding-top: 70px;
  }
}

@media screen and (max-width: 620px) {
  .aboutMe__hero-text-wrapper {
    /* margin-top: 200px; */
  }
}

@media screen and (max-width: 991px) {
  .aboutMe__hero-text-wrapper {
    margin-top: 100px;
  }
}

@media screen and (max-width: 991px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media screen and (max-width: 991px) {
  .aboutMe__hero-text-wrapper {
    margin-left: auto;
    margin-right: auto;
    white-space: normal;
  }
  .aboutMe__hero-img {
    flex: 1;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  /* Collapses the Image and text giving it a slimmer/mobile look */
  .col {
    max-width: 100%;
    flex-basis: 100%;
  }
}
