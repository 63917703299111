/* Style inputs with type="text", select elements and textareas */
text[for="name"],
text[for="email"],
text[for="number"] {
  display: flex;
  align-items: center;
  max-width: fit-content;
  min-width: 100%; /* Full width */
  padding: 12px; /* Some padding */
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  background-color: rgb(122, 146, 122);
}

label[for="name"],
label[for="email"],
label[for="number"] {
  color: rgb(122, 146, 122);
  padding: 12px;
}

/* Add a background color and some padding around the form */
.containerAboutMe {
  border-radius: 5px;
  background-color: #1c3723;
  padding: 20px;
  border: #1c3723;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  max-width: fit-content;
  height: 535px;
  width: 800px;
}
