.portfolio__grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  align-content: center;
  margin-left: 10px;
  padding: 1rem;
  /* padding-bottom: 2rem; */
}

.portfolio__grid-child {
  width: 100%;
}
.portfolio__grid-child:nth-child(2) {
}

.portfolio__grid-child-reverse:nth-child(2) {
  grid-row: 1;
}

.portfolio__page-subtitle1,
.portfolio__page-subtitle2 {
  padding: 0.5rem;
  white-space: pre-wrap;
}

.portfolio__top-line {
  color: #f00946;
  padding-top: 1rem;
  font-size: 1.6rem;
  text-align: center;
  font-weight: bold;
}

.portfolio__top-heading {
  font-size: 1.8rem;
  text-align: center;
  font-weight: bolder;
}

.dark {
  color: #1c3723;
  text-align: center;
}

.darkBg {
  background-color: #1c3723;
}

.portfolio__page-info-wrapper {
  flex-wrap: wrap;
  grid-area: portfolio__page-info-wrapper;
  max-width: max-content;
  width: 100%;
  margin: auto;
  /* padding: 3rem; */
}

.portfolio-links {
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 50px;
  padding: 2rem;
}

.portfolio__page-img {
  max-width: 100%;
  padding: 2rem;
}

@media only screen and (max-width: 768px) {
  .portfolio__grid-container {
    display: grid;
    grid-template-columns: 1fr;
    padding: 1rem;
    margin-bottom: 10rem;
  }
  .portfolio__page-img {
    max-width: 100%;
  }
  .portfolio-links {
  }
  .portfolio-links__btn {
    max-width: 50%;
  }

  .portfolio__grid-child {
    padding: 1.5rem;
  }

  .portfolio__grid-child:nth-child(2) {
    margin-top: -8rem;
  }

  .portfolio__page-info-wrapper {
    flex-wrap: wrap;
    grid-area: portfolio__page-info-wrapper;
    max-width: max-content;
    width: 100%;
    padding-top: 4rem;
  }
}

@media only screen and (max-width: 768px) {
  .portfolio__grid-child {
    margin-right: -3.5rem;
  }

  .dark {
    font-size: 1.8rem;
  }
}
