.navbar {
  background: #1c3723a2;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  z-index: 999;
}

.navbar-non-home {
  background: #1c3723;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  /* top: 0; */
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 80px;
}
/* Spaces out the container */
.WholeContainer {
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.navbar-logo {
  width: 150px;
}

/* Displays the links horizontally */
.nav-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  justify-content: end;
  right: 0;
}

.nav-item {
  height: 80px;
  border-bottom: 2px solid transparent;
}

.nav-item:hover {
  border-bottom: 2px solid #f00946;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.fa-bars {
  color: #fff;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .navbar-icon {
    margin-top: -30px;
    width: 150px;
  }
  .navbar.active {
    background: #1c3723;
  }
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    height: 91.5vh;
    position: absolute;
    top: 70px;
    opacity: 1;
    transition: all 0s ease;
    display: none;
  }

  .nav-menu.active {
    background: #1c3723;
    right: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
    visibility: visible;
    display: block;
  }

  .nav-links {
    flex: 1;
    justify-content: start;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 100%;
  }

  /* .navbar-logo {
    width: 130px;
  } */

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
  }
}

@media screen and (max-width: 320px) {
  .navbar-logo {
    width: 100px;
  }
  .menu-icon {
    margin-right: -0.5rem;
  }
}
